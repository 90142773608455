import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Header from './Header';
import Contract from '../components/Contract';
import Taxonomy from '../components/Taxonomy';
import Groups from '../components/Groups';
import DataModels from '../components/DataModels';
import Submit from '../components/Submit'
import useValidation from '../hooks/useValidation';

function EditContract() {
    const location = useLocation()
    const { contractDetail } = location.state || {};
    const [contractName, setContractName] = useState(contractDetail.contract_name);
    const [projectName, setProjectName] = useState(contractDetail.project_name);
    const [locationName, setLocationName] = useState(contractDetail.location);
    const [taxonomyName, setTaxonomyName] = useState(contractDetail.taxonomy.name);
    const [taxonomyDescription, setTaxonomyDescription] = useState(contractDetail.taxonomy.description);
    const [enforced, setEnforced] = useState(contractDetail.taxonomy.enforced);
    const [policyTags, setPolicyTags] = useState(contractDetail.taxonomy.policy_tags);
    const [groups, setGroups] = useState(contractDetail.groups);
    const [dataModels, setDataModels] = useState([]);
    
    const disabled = true;

    const { errors, setErrors, validate } = useValidation({
        contractName,
        projectName,
        locationName,
        taxonomyName,
        ...policyTags.reduce((acc, tag, index) => ({ ...acc, [`policyTagName${index}`]: tag.name }), {}),
        ...groups.reduce((acc, group, index) => ({ 
            ...acc, 
            [`groupName${index}`]: group.name, 
            [`groupPolicyTag${index}`]: group.policy_tag 
        }), {}),
        ...dataModels.reduce((acc, model, index) => ({
            ...acc,
            [`datasetName${index}`]: model.dataset_name,
            [`tableName${index}`]: model.table_name,
            [`schema${index}`]: model.schema,
            ...Object.keys(model.policies).reduce((policyAcc, policy) => ({
                ...policyAcc,
                [`policy${index}.${policy}`]: model.policies[policy]
            }), {})
        }), {})
    });

    useEffect(() => {
        const parseDataModels = contractDetail.data_models.map(model => {
          return {
            ...model,
            edit: true
          };
        });
        setDataModels(parseDataModels);
      }, [contractDetail]);

    return (
        <Header>
            <h2>Edit Contract</h2>
            {contractDetail ? (
                <div>
                    <Contract
                        contractName={contractName}
                        setContractName={setContractName}
                        projectName={projectName}
                        setProjectName={setProjectName}
                        locationName={locationName}
                        setLocation={setLocationName}
                        errors={errors}
                        disabled={disabled}
                    />
                    <Taxonomy
                        taxonomyName={taxonomyName}
                        setTaxonomyName={setTaxonomyName}
                        taxonomyDescription={taxonomyDescription}
                        setTaxonomyDescription={setTaxonomyDescription}
                        enforced={enforced}
                        setEnforced={setEnforced}
                        policyTags={policyTags}
                        setPolicyTags={setPolicyTags}
                        errors={errors}
                        setErrors={setErrors}
                        disabled={disabled}
                    />
                    <Groups
                        groups={groups}
                        setGroups={setGroups}
                        policyTags={policyTags}
                        errors={errors}
                        setErrors={setErrors}
                    />
                    <DataModels
                        dataModels={dataModels}
                        setDataModels={setDataModels}
                        policyTags={policyTags}
                        errors={errors}
                        setErrors={setErrors}
                    />
                    <Submit
                        contractName={contractName}
                        projectName={projectName}
                        locationName={locationName}
                        taxonomyName={taxonomyName}
                        taxonomyDescription={taxonomyDescription}
                        enforced={enforced}
                        policyTags={policyTags}
                        groups={groups}
                        dataModels={dataModels}
                        validate={validate}
                        mode="edit"
                    />
                </div>
            ) : (
                <div className="error-message">No contract data available.</div>
            )}
        </Header>
    );
};

export default EditContract;
