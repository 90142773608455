import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import Header from './Header';
import '../assets/Contracts.css';

function Contracts() {
    const navigate = useNavigate();
    const location = useLocation();
    const { user } = useAuth();
    const [contracts, setContracts] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage] = useState(location.state?.message || "");
    const [loading, setLoading] = useState(false);
    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const fetchContracts = async () => {
            const token = localStorage.getItem('token');
            if (!token) {
                setErrorMessage('No token found. Please login again.');
                return;
            }

            setLoading(true);
            try {
                const response = await fetch(`${apiUrl}/contracts/${user.email}`, {
                    headers: { 'App-Authorization': token }
                });
                const data = await response.json();

                if (response.ok) {
                    setContracts(data);
                } else {
                    setErrorMessage(`${data.error}. Please login again.`);
                }
            } catch (error) {
                setErrorMessage('Failed to fetch contracts. Please login again.');
            } finally {
                setLoading(false);
            }
        };

        fetchContracts();
    }, [user, apiUrl]);

    const handleContractClick = (contract) => {
        navigate("/contract", { state: { contract } });
    }

    return (
        <Header>
            <div className="container">
                <h2>My Contracts</h2>
                {loading && (
                    <div className="loading-overlay">
                        <div className="spinner"></div>
                        <div className="message">Loading Contracts...</div>
                    </div>
                )}
                {errorMessage ? (
                    <div className="error-message">{errorMessage}</div>
                ) : (
                    <ul className="contracts-list">
                        {contracts.map(contract => (
                            <li key={contract.id}>
                                <div className="contract-link" onClick={() => handleContractClick(contract)}>
                                    <strong>{contract.contract_name}</strong>
                                    <div className="project-details">
                                        <span>{contract.project_name}</span>
                                        <span className="separator">|</span>
                                        <span>{contract.location}</span>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
            <div>
                {successMessage && (<div className="popup-message">{successMessage}</div>)}
            </div>
        </Header>
    );
};

export default Contracts;
