import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useAuth } from '../contexts/AuthContext';

function Groups({ groups, setGroups, policyTags, setErrors, errors }) {
    const { user } = useAuth();
    const [googleGroups, setGoogleGroups] = useState([]);
    const localGroups = [
        { "name": "gcp-team-yoda-data-services-admin", "email": "gcp-team-yoda-data-services-admin@seb.se" },
        { "name": "gcp-team-yoda-data-services-viewer", "email": "gcp-team-yoda-data-services-viewer@seb.se" },
        { "name": "gcp-team-yoda-security", "email": "gcp-team-yoda-security@seb.se" }
    ];
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const apiUrl = process.env.REACT_APP_API_URL;
    const workspace = process.env.REACT_APP_WORKSPACE;

    useEffect(() => {
        if (groups.length === 0) {
            setGroups([{ name: null, email: null, policy_tag: null }]);
        }
    }, [groups, setGroups]);

    useEffect(() => {
        const fetchGoogleGroups = async () => {
            if (workspace === "local") {
                setGoogleGroups(localGroups);
            } else {
                const token = localStorage.getItem('token');
                if (!token) {
                    setErrorMessage('No token found. Please login again.');
                    return;
                }

                setLoading(true);
                try {
                    const response = await fetch(`${apiUrl}/google-groups/${user.email}`, {
                        headers: { 'App-Authorization': token }
                    });
                    const data = await response.json();

                    if (response.ok) {
                        setGoogleGroups(data);
                    } else {
                        setErrorMessage(`${data.error}.`);
                    }
                } catch (error) {
                    setErrorMessage('Failed to fetch groups.');
                } finally {
                    setLoading(false);
                }
            }
        };

        fetchGoogleGroups();
    }, [user, apiUrl]);

    const getGroupOptions = (currentName) => {
        return googleGroups.filter(group =>
            group.name === currentName || !groups.some(selectedGroup => selectedGroup.name === group.name)
        ).map(group => ({ value: group.name, label: group.name }));
    };

    const getPolicyTagOptions = () => {
        return policyTags.map(tag => ({ value: tag.name, label: tag.name }));
    };

    const handleGroupChange = (index, selectedOption) => {
        const updatedGroups = [...groups];
        const selectedGroup = googleGroups.find(group => group.name === selectedOption.value);
        updatedGroups[index] = { ...updatedGroups[index], name: selectedGroup.name, email: selectedGroup.email };
        setGroups(updatedGroups);
    };

    const handlePolicyTagChange = (index, selectedOption) => {
        const updatedGroups = [...groups];
        updatedGroups[index].policy_tag = selectedOption.value;
        setGroups(updatedGroups);
    };

    const handleAddGroup = () => {
        const values = [...groups];
        values.push({ name: null, email: null, policy_tag: null });
        setGroups(values);
    };

    const handleRemoveGroup = (index) => {
        const updatedGroups = [...groups];
        updatedGroups.splice(index, 1);
        setGroups(updatedGroups);

        const newErrors = { ...errors };
        Object.keys(newErrors).forEach(key => {
            if (key.startsWith("groupName") || key.startsWith("groupPolicyTag")) {
                delete newErrors[key];
            }
        });
        setErrors(newErrors);
    };

    return (
        <div className="form">
            <h3>Groups</h3>
            {loading && (
                <div className="form-loading-overlay">
                    <div className="spinner"></div>
                </div>
            )}
            <div className="form-group">
                {groups.map((group, index) => (
                    <div key={index} className="group-item">
                        <Select
                            name="name"
                            value={group.name ? { value: group.name, label: group.name } : null}
                            onChange={(selectedOption) => handleGroupChange(index, selectedOption)}
                            options={getGroupOptions(group.name)}
                            placeholder="Select a Group"
                            isSearchable
                            className={`react-select-container ${errors[`groupName${index}`] ? "error-border" : ""}`}
                            classNamePrefix="react-select"
                        />
                        <Select
                            name="policy_tag"
                            value={group.policy_tag ? { value: group.policy_tag, label: group.policy_tag } : null}
                            onChange={(selectedOption) => handlePolicyTagChange(index, selectedOption)}
                            options={getPolicyTagOptions()}
                            placeholder="Select a Policy Tag"
                            isSearchable
                            className={`react-select-container ${errors[`groupPolicyTag${index}`] ? "error-border" : ""}`}
                            classNamePrefix="react-select"
                        />
                        <button type="button" onClick={() => handleRemoveGroup(index)} className="remove-button" disabled={index === 0}>Remove</button>
                    </div>
                ))}
            </div>
            <button type="button" onClick={handleAddGroup} className="add-button">Add Group</button>
            {errorMessage && (<div className="error-message">{errorMessage}</div>)}
        </div>
    );
}

export default Groups;
