import React, { createContext, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [token, setToken] = useState(localStorage.getItem('token') || null);
    const [user, setUser] = useState(token ? jwtDecode(localStorage.getItem('token')) : null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const apiUrl = process.env.REACT_APP_API_URL;

    const handleLoginReponse = async (response, loginType) => {
        const accessToken = loginType === "dev" ? response.devToken : response.access_token;

        setLoading(true);
        try {
            const loginResponse = await fetch(`${apiUrl}/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ accessToken })
            });

            if (loginResponse.ok) {
                const data = await loginResponse.json();
                const decodedToken = jwtDecode(data.token);

                setError(null);
                setToken(data.token);
                setUser(decodedToken);
                localStorage.setItem("token", data.token);
                navigate("/contracts");
            } else {
                setError("Login Failed");
            }
        } catch (error) {
            setError("Error fetching user info");
        } finally {
            setLoading(false);
        }
    };

    const loginSuccess = (response) => {
        handleLoginReponse(response, "google");
    };

    const loginFailure = (error) => {
        setError("Google Login failed");
    };

    const login = useGoogleLogin({ onSuccess: loginSuccess, onError: loginFailure });

    const devLogin = () => {
        const devToken = "dev_token";
        handleLoginReponse({ devToken }, "dev");
    };

    const logout = () => {
        setToken(null);
        setUser(null);
        localStorage.removeItem('token');
        navigate('/login');
    };

    return (
        <AuthContext.Provider value={{ token, user, error, devLogin, login, logout }}>
            {loading && (
                <div className="loading-overlay">
                    <div className="spinner"></div>
                    <div className="message">Signing In...</div>
                </div>
            )}
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};
