import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from '../contexts/AuthContext';

function Submit({
    contractName, projectName, locationName,
    taxonomyName, taxonomyDescription, enforced, policyTags,
    groups, dataModels, validate, mode
}) {
    const navigate = useNavigate();
    const { user } = useAuth();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const apiUrl = process.env.REACT_APP_API_URL;

    const handleSubmit = async (e) => {
        e.preventDefault();

        const token = localStorage.getItem('token');
        if (!token) {
            setError('No token found. Please login again.');
            return;
        }

        if (!validate()) {
            setError("Please fill in all required fields.");
            return;
        }

        let parsedDataModels;
        try {
            parsedDataModels = dataModels.map(model => {
                const { edit, ...rest } = model;
                return { ...rest }
            });
        } catch (error) {
            setError("Incorrect JSON format in policies.");
            return;
        }

        const formData = {
            "owner": user.email,
            "contract_name": contractName,
            "project_name": projectName,
            "location": locationName,
            "taxonomy": {
                "name": taxonomyName,
                "description": taxonomyDescription,
                "enforced": enforced,
                "policy_tags": policyTags
            },
            "groups": groups,
            "data_models": parsedDataModels
        };

        setLoading(true);

        try {
            const response = await fetch(`${apiUrl}/deploy`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "App-Authorization": token
                },
                body: JSON.stringify(formData)
            });

            const responseData = await response.json();
            console.log(JSON.stringify(responseData, null, 2));
            if (responseData.status === "success") {
                setError(null);
                setLoading(false);
                navigate("/contracts", { state: { message: "Contract Successfully Deployed" } });
            } else {
                setError("Failed to deploy contract.");
                setLoading(false);
            }
        } catch (error) {
            setError("An error occurred while submitting the form.");
            setLoading(false);
        }
    };

    return (
        <div className="submit">
            {loading && (
                <div className="loading-overlay">
                    <div className="spinner"></div>
                    <div className="message">{mode === "create" ? "Creating Contract..." : "Updating Contract..."}</div>
                </div>
            )}
            {error && <div className="error-message">{error}</div>}
            <button type="submit" className="button" onClick={handleSubmit}>Submit</button>
        </div>
    );
}

export default Submit;
