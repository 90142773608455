import { useState } from 'react';

function useValidation(fields) {
    const [errors, setErrors] = useState({});

    const validate = () => {
        const newErrors = {};
        Object.keys(fields).forEach(field => {
            if (!fields[field]) {
                newErrors[field] = true;
            }
        });

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    return { errors, setErrors, validate };
}

export default useValidation;
