import React from 'react';
import Select from 'react-select';

function Contract({
    contractName, setContractName,
    projectName, setProjectName,
    locationName, setLocationName,
    errors, disabled
}) {
    const locationOptions = [
        { value: "europe-north1", label: "Single-Region" },
        { value: "EU", label: "Multi-Region" }
    ];

    return (
        <div className="form">
            <h3>General</h3>
            <div className="form-group">
                <label>Contract Name:</label>
                <input
                    className={errors.contractName ? "error-border" : ""}
                    type="text"
                    value={contractName}
                    onChange={(e) => setContractName(e.target.value)}
                    disabled={disabled}
                />
            </div>
            <div className="form-group">
                <div className="project-item">
                    <input
                        className={errors.projectName ? "error-border" : ""}
                        type="text"
                        placeholder="Project ID"
                        value={projectName}
                        onChange={(e) => setProjectName(e.target.value)}
                        disabled={disabled}
                    />
                    <Select
                        name="location"
                        value={locationOptions.find(option => option.value === locationName) || null}
                        onChange={(selectedOption) => setLocationName(selectedOption.value)}
                        options={locationOptions}
                        placeholder="Select a Region"
                        isSearchable
                        className={`react-select-container ${errors.locationName ? "error-border" : ""}`}
                        classNamePrefix="react-select"
                        isDisabled={disabled}
                    />
                </div>
            </div>
        </div>
    );
}

export default Contract;
