import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import '../assets/Header.css';
import logo from '../assets/logo.png'

function Header({ children }) {
    const { token, user, logout } = useAuth();
    const location = useLocation();
    const isContractsPage = location.pathname === '/contracts';
    const isNewContractPage = location.pathname === '/new-contract';

    return (
        <div>
            <header>
                <nav className="navbar">
                    <ul className="nav-list">
                        {token ? (
                            <>
                                <li className="logo">
                                    <Link to="/contracts"><img src={logo} className="logo-img" alt="Logo" /></Link>
                                </li>
                                <li className="welcome-message">Welcome, {user.name}</li>
                                <li><Link to="/contracts" className={isContractsPage ? "active" : ""}>My Contracts</Link></li>
                                <li><Link to="/new-contract" className={isNewContractPage ? "active" : ""}>New Contract</Link></li>
                                <li className="logout"><Link onClick={logout}>Logout</Link></li>
                            </>
                        ) : (
                            <>
                                <li className="logo">
                                    <Link to="/login"><img src={logo} className="logo-img" alt="Logo" /></Link>
                                </li>
                            </>
                        )}
                    </ul>
                </nav>
            </header>
            <main>
                {children}
            </main>
        </div>
    );
}

export default Header;
