import React, { useState } from 'react';
import '../assets/SchemaTable.css';

function SchemaTable({ schema, isNested = false }){
    const [collapsed, setCollapsed] = useState({});

    const toggleCollapse = (columnName) => {
        setCollapsed(prev => ({ ...prev, [columnName]: !prev[columnName] }));
    };

    const parseSchema = (schema) => {
        let result = [];

        for (let column in schema.properties) {
            const property = schema.properties[column];

            if (property.type === 'object') {
                result.push({
                    columnName: column,
                    description: property.description || "N/A",
                    dataType: property.type,
                    required: schema.required?.includes(column) || false,
                    nestedSchema: property
                });
            } else if (property.type === 'array' && property.items) {
                if (property.items.type === 'object') {
                    result.push({
                        columnName: column,
                        description: property.description || "N/A",
                        dataType: property.type,
                        required: schema.required?.includes(column) || false,
                        nestedSchema: property.items
                    });
                } else {
                    result.push({
                        columnName: column,
                        description: property.description || "N/A",
                        dataType: `array of ${property.items.type}`,
                        required: schema.required?.includes(column) || false
                    });
                }
            } else {
                result.push({
                    columnName: column,
                    description: property.description || "N/A",
                    dataType: property.type,
                    required: schema.required?.includes(column) || false
                });
            }
        }

        return result;
    };

    const rows = parseSchema(schema);

    return (
        <div className={`schema-container ${isNested ? 'nested-schema' : ''}`}>
            <table>
                {!isNested && (
                    <thead>
                        <tr>
                            <th>Column Name</th>                            
                            <th>Description</th>
                            <th>Data Type</th>
                            <th>Required</th>
                        </tr>
                    </thead>
                )}
                <tbody>
                    {rows.map((row, index) => (
                        <React.Fragment key={index}>
                            <tr onClick={() => row.nestedSchema && toggleCollapse(row.columnName)}>
                                <td>
                                    {row.nestedSchema ? (
                                        <span className={`toggle-icon ${collapsed[row.columnName] ? 'expanded' : 'collapsed'}`}>
                                            {collapsed[row.columnName]}
                                        </span>
                                    ) : null} 
                                    {row.columnName}
                                </td>
                                <td>{row.description}</td>
                                <td>{row.dataType}</td>
                                <td>{row.required ? 'Yes' : 'No'}</td>
                            </tr>
                            {row.nestedSchema && (
                                <tr>
                                    <td colSpan="4" className={`collapsible-content ${collapsed[row.columnName] ? 'expanded' : 'collapsed'}`}>
                                        {collapsed[row.columnName] && (
                                            <SchemaTable schema={row.nestedSchema} isNested={true} />
                                        )}
                                    </td>
                                </tr>
                            )}
                        </React.Fragment>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default SchemaTable;
