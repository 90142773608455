import React, { useState, useEffect, useRef } from 'react';
import SchemaTable from '../components/SchemaTable';
import Select from 'react-select';

function DataModels({ dataModels, setDataModels, policyTags, setErrors, errors }) {
    const [errorMessage, setErrorMessage] = useState({});
    const fileInputRefs = useRef([]);

    useEffect(() => {
        if (dataModels.length === 0) {
            setDataModels([{ dataset_name: "", table_name: "", schema: null, policies: {}, allPolicy: null }]);
        }
    }, [dataModels, setDataModels]);

    const getPolicyTagOptions = () => {
        return policyTags.map(tag => ({ value: tag.name, label: tag.name }));
    };

    const initializePolicies = (schema, currentPolicies) => {
        const policies = {};
        const validFields = new Set();
    
        const traverseSchema = (schema, parentField = "") => {
            Object.keys(schema.properties || {}).forEach(field => {
                const currentField = parentField ? `${parentField}.${field}` : field;
                const property = schema.properties[field];
    
                validFields.add(currentField);
    
                if (property.type === "object") {
                    traverseSchema(property, currentField);
                } else if (property.type === "array" && property.items.type === "object") {
                    traverseSchema(property.items, currentField);
                } else {
                    policies[currentField] = currentPolicies[currentField] !== undefined ? currentPolicies[currentField] : null;
                }
            });
        };
    
        traverseSchema(schema);

        Object.keys(currentPolicies).forEach(key => {
            if (validFields.has(key)) {
                policies[key] = currentPolicies[key];
            }
        });
        return policies;
    };
    
    const handleSchemaUpload = (index, event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                try {
                    const schema = JSON.parse(e.target.result);
                    const updatedDataModels = [...dataModels];
                    updatedDataModels[index].schema = schema;
                    updatedDataModels[index].policies = initializePolicies(schema, updatedDataModels[index].policies);
                    setErrorMessage(prevErrors => ({ ...prevErrors, [index]: "" }));
                    setDataModels(updatedDataModels);
                } catch (error) {
                    setErrorMessage(prevErrors => ({ ...prevErrors, [index]: "Failed to parse JSON Schema" }));
                }
            };
            reader.readAsText(file);
        }
    };

    const handleSchemaClear = (index) => {
        const updatedDataModels = [...dataModels];
        updatedDataModels[index].schema = "";
        setErrorMessage(prevErrors => ({ ...prevErrors, [index]: "" }));
        setDataModels(updatedDataModels);
        
        fileInputRefs.current[index].value = null;
    };

    const handlePolicyChange = (index, field, value) => {
        const updatedDataModels = [...dataModels];
        updatedDataModels[index].policies[field] = value;
        setDataModels(updatedDataModels);
    };

    const handleAllPolicyChange = (index, selectedOption) => {
        const updatedDataModels = [...dataModels];
        const updatedPolicies = { ...updatedDataModels[index].policies };
        Object.keys(updatedPolicies).forEach(field => {
            updatedPolicies[field] = selectedOption.value;
        });
        updatedDataModels[index].policies = updatedPolicies;
        setDataModels(updatedDataModels);
    };

    const handleDataModelChange = (index, event) => {
        const { name, value } = event.target;
        const updatedDataModels = [...dataModels];
        updatedDataModels[index][name] = value;
        setDataModels(updatedDataModels);
    };

    const handleAddDataModel = () => {
        const values = [...dataModels];
        values.push({ dataset_name: "", table_name: "", schema: null, policies: {}, allPolicy: null });
        setDataModels(values);
    };

    const handleRemoveDataModel = (index) => {
        const updatedDataModels = [...dataModels];
        updatedDataModels.splice(index, 1);
        setDataModels(updatedDataModels);
        setErrorMessage(prevErrors => {
            const newErrors = { ...prevErrors };
            delete newErrors[index];
            return newErrors;
        });

        const newErrors = { ...errors };
        Object.keys(newErrors).forEach(key => {
            if (key.startsWith("datasetName") || key.startsWith("tableName") || key.startsWith("schema") || key.startsWith("policy")) {
                delete newErrors[key];
            }
        });
        setErrors(newErrors);
    };

    const renderPolicies = (schema, policies, index, parentField = "") => {
        if (!schema || typeof schema !== "object") return null;

        return Object.keys(schema.properties || {}).map((field) => {
            const currentField = parentField ? `${parentField}.${field}` : field;
            const property = schema.properties[field];

            if (property.type === "object") {
                return (
                    <div key={currentField}>
                        {renderPolicies(property, policies, index, currentField)}
                    </div>
                );
            }

            if (property.type === "array" && property.items.type === "object") {
                return (
                    <div key={currentField}>
                        {renderPolicies(property.items, policies, index, currentField)}
                    </div>
                );
            }

            return (
                <div key={currentField} className="policy-field">
                    <label>{currentField}:</label>
                    <Select
                        name={currentField}
                        value={policies[currentField] ? { value: policies[currentField], label: policies[currentField] } : null}
                        onChange={(selectedOption) => handlePolicyChange(index, currentField, selectedOption.value)}
                        options={getPolicyTagOptions()}
                        placeholder="Policy Tag"
                        isSearchable
                        className={`react-select-container ${errors[`policy${index}.${currentField}`] ? "error-border" : ""}`}
                        classNamePrefix="react-select"
                    />
                </div>
            );
        });
    };

    return (
        <div className="form">
            <h3>Data Models</h3>
            {dataModels.map((model, index) => (
                <div key={index} className="model-item">
                    <div className="form-group">
                        <label>Dataset Name:</label>
                        <input
                            className={errors[`datasetName${index}`] ? "error-border" : ""}
                            type="text"
                            name="dataset_name"
                            value={model.dataset_name}
                            onChange={(event) => handleDataModelChange(index, event)}
                            disabled={model.edit && !!model.dataset_name}
                        />
                    </div>
                    <div className="form-group">
                        <label>Table Name:</label>
                        <input
                            className={errors[`tableName${index}`] ? "error-border" : ""}
                            type="text"
                            name="table_name"
                            value={model.table_name}
                            onChange={(event) => handleDataModelChange(index, event)}
                            disabled={model.edit && !!model.dataset_name}
                        />
                    </div>
                    <div className="form-group">
                        <label>Schema:</label>
                        {errorMessage[index] && <div className="error-message">{errorMessage[index]}</div>}
                        {errors[`schema${index}`] && <div className="error-message">No Schema Uploaded</div>}
                        {!errorMessage[index] && model.schema && (<SchemaTable schema={model.schema} />)}
                        <input
                            type="file"
                            id={`file-upload-${index}`}
                            name="schema"
                            accept=".json"
                            onChange={(event) => handleSchemaUpload(index, event)}
                            className="file-input"
                            ref={(element) => (fileInputRefs.current[index] = element)}
                        />
                        <div className="schema-upload" onClick={() => document.getElementById(`file-upload-${index}`).click()}>
                            Upload Schema
                        </div>
                        <div className="schema-clear" onClick={() => handleSchemaClear(index)}>
                            Clear Schema
                        </div>
                    </div>
                    {model.schema && (
                        <div className="form-group">
                            <div className="policy-field">
                                <label>Policies:</label>
                                <Select
                                    name={`allPolicy${index}`}
                                    value={null}
                                    onChange={(selectedOption) => handleAllPolicyChange(index, selectedOption)}
                                    options={getPolicyTagOptions()}
                                    placeholder="Set all policies"
                                    isSearchable
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                />
                            </div>
                            {renderPolicies(model.schema, model.policies, index)}
                        </div>
                    )}
                    <button type="button" onClick={() => handleRemoveDataModel(index)} className="remove-button" disabled={index === 0 || model.edit}>Remove</button>
                </div>
            ))}
            <button type="button" onClick={handleAddDataModel} className="add-button">Add Data Model</button>
        </div>
    );
}

export default DataModels;
