import React, { useEffect } from 'react';

function Taxonomy({
    taxonomyName, setTaxonomyName,
    taxonomyDescription, setTaxonomyDescription,
    enforced, setEnforced,
    policyTags, setPolicyTags,
    errors, setErrors,
    disabled
}) {
    useEffect(() => {
        if (policyTags.length === 0) {
            setPolicyTags([{ name: '', description: '' }]);
        }
    }, [policyTags, setPolicyTags]);

    const handlePolicyTagChange = (index, event) => {
        const { name, value } = event.target;
        const values = [...policyTags];
        values[index][name] = value;
        setPolicyTags(values);
    };

    const handleAddPolicyTag = () => {
        const values = [...policyTags];
        values.push({ name: '', description: '' });
        setPolicyTags(values);
    };

    const handleRemovePolicyTag = (index) => {
        const values = [...policyTags];
        if (values.length > 1) {
            values.splice(index, 1);
            setPolicyTags(values);
        }

        const newErrors = { ...errors };
        Object.keys(newErrors).forEach(key => {
            if (key.startsWith("policyTagName")) {
                delete newErrors[key];
            }
        });
        setErrors(newErrors);
    };

    return (
        <div className="form">
            <h3>Taxonomy and Policy Tags</h3>
            <div className="form-group">
                <label>Taxonomy Name:</label>
                <input
                    className={errors.taxonomyName ? "error-border" : ""}
                    type="text"
                    value={taxonomyName}
                    onChange={(event) => setTaxonomyName(event.target.value)}
                    disabled={disabled}
                />
            </div>
            <div className="form-group">
                <label>Taxonomy Description:</label>
                <input
                    type="text"
                    value={taxonomyDescription}
                    onChange={(event) => setTaxonomyDescription(event.target.value)}
                    disabled={disabled}
                />
            </div>
            <div className="form-group">
                <label>Enforced:</label>
                <input
                    type="checkbox"
                    checked={enforced}
                    onChange={(event) => setEnforced(event.target.checked)}
                    disabled={disabled}
                />
            </div>
            <div className="form-group">
                <label>Policy Tags:</label>
                {policyTags.map((tag, index) => (
                    <div key={index} className="policy-tag-item">
                        <input
                            className={errors[`policyTagName${index}`] ? "error-border" : ""}
                            type="text"
                            name="name"
                            placeholder="Tag Name"
                            value={tag.name}
                            onChange={(event) => handlePolicyTagChange(index, event)}
                        />
                        <input
                            type="text"
                            name="description"
                            placeholder="Tag Description"
                            value={tag.description}
                            onChange={(event) => handlePolicyTagChange(index, event)}
                        />
                        <button type="button" onClick={() => handleRemovePolicyTag(index)} className="remove-button" disabled={index === 0}>Remove</button>
                    </div>
                ))}
                <button type="button" onClick={handleAddPolicyTag} className="add-button">Add Policy Tag</button>
            </div>
        </div>
    );
}

export default Taxonomy;
