import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from './Header';
import '../assets/ContractDetail.css';
import SchemaTable from '../components/SchemaTable';
import Select from 'react-select';

function ContractDetail() {
    const navigate = useNavigate();
    const location = useLocation();
    const [contract] = useState(location.state?.contract || null);
    const [contractDetail, setContractDetail] = useState(null);
    const [contractVersions, setContractVersions] = useState([]);
    const [contractInitialVersion, setContractInitialVersion] = useState(null);
    const [contractVersionChanged, setContractVersionChanged] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const apiUrl = process.env.REACT_APP_API_URL;

    const formatVersion = (version) => `Version ${version}`;

    useEffect(() => {
        const fetchContract = async () => {
            const token = localStorage.getItem('token');
            if (!token) {
                setErrorMessage('No token found. Please login again.');
                return;
            }

            try {
                const response = await fetch(`${apiUrl}/contract/${contract.id}`, {
                    headers: { 'App-Authorization': token }
                });
                const data = await response.json();
                if (response.ok) {
                    setContractDetail(data);
                    setContractInitialVersion(formatVersion(data.version));
                } else {
                    setErrorMessage(`${data.error}. Please login again.`);
                }
            } catch (error) {
                setErrorMessage('Failed to fetch contract. Please login again.');
            }
        };

        const fetchContractVersions = async () => {
            const token = localStorage.getItem('token');
            if (!token) {
                setErrorMessage('No token found. Please login again.');
                return;
            }

            try {
                const response = await fetch(`${apiUrl}/contract-versions/${contract.id}`, {
                    headers: { 'App-Authorization': token }
                });
                const data = await response.json();
                if (response.ok) {
                    const options = data.map(contract => ({ value: contract.id, label: formatVersion(contract.version) }));
                    setContractVersions(options);
                } else {
                    setErrorMessage(`${data.error}. Please login again.`);
                }
            } catch (error) {
                setErrorMessage('Failed to fetch contract versions. Please login again.');
            }
        };

        if (contract) {
            fetchContract();
            fetchContractVersions();
        }
    }, [contract, apiUrl]);

    const handleVersionChange = async (selectedOption) => {
        const token = localStorage.getItem('token');
        if (!token) {
            setErrorMessage('No token found. Please login again.');
            return;
        }

        try {
            const response = await fetch(`${apiUrl}/contract/${selectedOption.value}`, {
                headers: { 'App-Authorization': token }
            });
            const data = await response.json();
            if (response.ok) {
                setContractDetail(data);
                setContractVersionChanged(selectedOption.label !== contractInitialVersion);
            } else {
                setErrorMessage(`${data.error}. Please login again.`);
            }
        } catch (error) {
            setErrorMessage('Failed to fetch selected contract version. Please login again.');
        }
    };

    if (!contractDetail) {
        return (
            <Header>
                <div className="loading-overlay">
                    <div className="spinner"></div>
                    <div className="message">Loading Contract...</div>
                </div>
            </Header>
        );
    }

    const handleEdit = (e) => {
        navigate("/edit-contract", { state: { contractDetail } });
    };

    return (
        <Header>
            <h2>{contractDetail.contract_name}</h2>
            <div className="contract-versions">
                <Select
                    name="version"
                    value={contractVersions.find(option => option.label === formatVersion(contractDetail.version))}
                    onChange={handleVersionChange}
                    options={contractVersions}
                    isSearchable
                    className="react-select-container"
                    classNamePrefix="react-select"
                />
            </div>
            <div className="general">
                <h3>General</h3>
                <p><strong>Location:</strong> {contractDetail.location}</p>
                <p><strong>Project Name:</strong> {contractDetail.project_name}</p>
            </div>

            <div className="taxonomy-policy-tags">
                <h3>Taxonomy and Policy Tags</h3>
                <p><strong>Taxonomy Name:</strong> {contractDetail.taxonomy.name}</p>
                <p><strong>Description:</strong> {contractDetail.taxonomy.description}</p>
                <p><strong>Enforced:</strong> {contractDetail.taxonomy.enforced ? 'Yes' : 'No'}</p>
                <p><strong>Policy Tags:</strong></p>
                <table>
                    <thead>
                        <tr>
                            <th>Policy Tag</th>
                            <th>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        {contractDetail.taxonomy.policy_tags.map(tag => (
                            <tr key={tag.name}>
                                <td>{tag.name}</td>
                                <td>{tag.description}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <div className="groups">
                <h3>Groups</h3>
                <table>
                    <thead>
                        <tr>
                            <th>Group Name</th>
                            <th>Group Email</th>
                            <th>Policy Tag</th>
                        </tr>
                    </thead>
                    <tbody>
                        {contractDetail.groups.map(group => (
                            <tr key={group.email}>
                                <td>{group.name}</td>
                                <td>{group.email}</td>
                                <td>{group.policy_tag}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <div className="data-models">
                <h3>Data Models</h3>
                {contractDetail.data_models.map(model => (
                    <div className="data-model" key={model.dataset_name}>
                        <p><strong>Dataset Name:</strong> {model.dataset_name}</p>
                        <p><strong>Table Name:</strong> {model.table_name}</p>
                        <p><strong>Schema:</strong></p>
                        <SchemaTable schema={model.schema} />
                        <p><strong>Policies:</strong></p>
                        <div className="policies">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Column Name</th>
                                        <th>Policy Tag</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.entries(model.policies).map(([key, value]) => (
                                        <tr key={key}>
                                            <td>{key}</td>
                                            <td>{value}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                ))}
            </div>
            {errorMessage && <p className="error-message">{errorMessage}</p>}
            <div className="submit">
                <button type="submit" className="button" onClick={handleEdit} disabled={contractVersionChanged}>Edit</button>
            </div>
        </Header>
    );
};

export default ContractDetail;
