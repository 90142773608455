import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import Header from './Header';
import googleLogo from '../assets/google-logo.svg'
import '../assets/Login.css'

function Login() {
    const { token, error, login, devLogin } = useAuth();
    const navigate = useNavigate();
    const workspace = process.env.REACT_APP_WORKSPACE;

    useEffect(() => {
        if (token) { navigate("/contracts"); }
    });

    return (
        <Header>
            <section id="features" className="features">
                <h2>Data Contract</h2>
                <div className="feature-cards">
                    <div className="card">
                        <h3>Secure</h3>
                        <p>Our data contracts are secured with top-notch encryption to ensure data privacy.</p>
                    </div>
                    <div className="card">
                        <h3>Reliable</h3>
                        <p>Experience reliable and consistent performance with our data contract solutions.</p>
                    </div>
                    <div className="card">
                        <h3>Transparent</h3>
                        <p>Maintain transparency and trust with clear and auditable data contracts.</p>
                    </div>
                </div>
            </section>
            <div className="login-container">
                <p>Use your Google Account to Sign-In</p>
                <button className="google-login-button" onClick={login}>
                    <img src={googleLogo} alt="Google logo" className="google-logo" />
                    Sign-In with Google SSO
                </button>
                {workspace === "local" && (
                    <button style={{ marginTop: '10px' }} onClick={devLogin}>
                        Dev Access
                    </button>
                )}
                {error && <div className="error-message">{error}</div>}
            </div>
        </Header>
    );
};

export default Login;