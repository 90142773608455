import React from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './pages/Login';
import Contracts from './pages/Contracts';
import ContractDetail from './pages/ContractDetail';
import NewContract from './pages/NewContract';
import EditContract from './pages/EditContract';
import PrivateRoute from './components/PrivateRoutes';
import { AuthProvider } from './contexts/AuthContext';
import './assets/App.css';

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

const App = () => {
    return (
        <GoogleOAuthProvider clientId={clientId}>
            <Router>
                <AuthProvider>
                    <Routes>
                        <Route path="/" element={<Login />} />
                        <Route path="/login" element={<Login />} />
                        <Route element={<PrivateRoute />}>
                            <Route path="/contracts" element={<Contracts />} />
                            <Route path="/contract" element={<ContractDetail />} />
                            <Route path="/new-contract" element={<NewContract />} />
                            <Route path="/edit-contract" element={<EditContract />} />
                        </Route>
                    </Routes>
                </AuthProvider>
            </Router>
        </GoogleOAuthProvider>
    );
};

export default App;