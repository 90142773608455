import React, { useState } from 'react';
import Header from './Header';
import Contract from '../components/Contract';
import Taxonomy from '../components/Taxonomy';
import Groups from '../components/Groups';
import DataModels from '../components/DataModels';
import Submit from '../components/Submit';
import useValidation from '../hooks/useValidation';
import "../assets/Form.css"

function NewContract() {
    const [contractName, setContractName] = useState('');
    const [projectName, setProjectName] = useState('');
    const [locationName, setLocationName] = useState('');
    const [taxonomyName, setTaxonomyName] = useState('');
    const [taxonomyDescription, setTaxonomyDescription] = useState('');
    const [enforced, setEnforced] = useState(true);
    const [policyTags, setPolicyTags] = useState([]);
    const [groups, setGroups] = useState([]);
    const [dataModels, setDataModels] = useState([]);
    const disabled = false;

    const { errors, setErrors, validate } = useValidation({
        contractName,
        projectName,
        locationName,
        taxonomyName,
        ...policyTags.reduce((acc, tag, index) => ({ ...acc, [`policyTagName${index}`]: tag.name }), {}),
        ...groups.reduce((acc, group, index) => ({ 
            ...acc, 
            [`groupName${index}`]: group.name, 
            [`groupPolicyTag${index}`]: group.policy_tag 
        }), {}),
        ...dataModels.reduce((acc, model, index) => ({
            ...acc,
            [`datasetName${index}`]: model.dataset_name,
            [`tableName${index}`]: model.table_name,
            [`schema${index}`]: model.schema,
            ...Object.keys(model.policies).reduce((policyAcc, policy) => ({
                ...policyAcc,
                [`policy${index}.${policy}`]: model.policies[policy]
            }), {})
        }), {})
    });

    return (
        <Header>
            <h2>New Contract</h2>
            <Contract
                contractName={contractName}
                setContractName={setContractName}
                projectName={projectName}
                setProjectName={setProjectName}
                locationName={locationName}
                setLocationName={setLocationName}
                errors={errors}
                disabled={disabled}
            />
            <Taxonomy
                taxonomyName={taxonomyName}
                setTaxonomyName={setTaxonomyName}
                taxonomyDescription={taxonomyDescription}
                setTaxonomyDescription={setTaxonomyDescription}
                enforced={enforced}
                setEnforced={setEnforced}
                policyTags={policyTags}
                setPolicyTags={setPolicyTags}
                errors={errors}
                setErrors={setErrors}
                disabled={disabled}
            />
            <Groups
                groups={groups}
                setGroups={setGroups}
                policyTags={policyTags}
                setErrors={setErrors}
                errors={errors}
            />
            <DataModels
                dataModels={dataModels}
                setDataModels={setDataModels}
                policyTags={policyTags}
                errors={errors}
                setErrors={setErrors}
            />
            <Submit
                contractName={contractName}
                projectName={projectName}
                locationName={locationName}
                taxonomyName={taxonomyName}
                taxonomyDescription={taxonomyDescription}
                enforced={enforced}
                policyTags={policyTags}
                groups={groups}
                dataModels={dataModels}
                validate={validate}
                mode="create"
            />
        </Header>
    );
}

export default NewContract;
